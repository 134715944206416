import { CheckBalanceReq, CheckBalanceRes, ColdConfig, LocationByCurrency } from 'ap-core'
import { prop } from 'ramda'
import { createApiRetryEffect } from '../store'
import { getAssetsEndpoints } from './api'
import { Assets } from './types'

const endpoints = getAssetsEndpoints()

export const getAssetsFx = createApiRetryEffect<void, Assets>({
  endpoint: prop('FETCH_ASSETS', endpoints),
})

export const getAccountsLocationsFx = createApiRetryEffect<void, LocationByCurrency[]>({
  endpoint: prop('FETCH_LOCATIONS_BY_LOCALE', endpoints),
})

export const checkBalanceFx = createApiRetryEffect<CheckBalanceReq, CheckBalanceRes>({
  endpoint: prop('CHECK_BALANCE', endpoints),
})

export const getColdConfigFx = createApiRetryEffect<void, ColdConfig>({
  endpoint: prop('GET_COLD_CONFIG', endpoints),
  method: 'get',
})
