import { isNilOrEmpty } from '@aifory/lil-tools-228'
import { Condition, Pin } from '@aifory/pochti-rabotaet'
import { Flex, Text } from '@mantine/core'
import { useViewportSize } from '@mantine/hooks'
import { closeAllModals, openModal } from '@mantine/modals'
import { captureException } from '@sentry/react'
import {
  BalanceText,
  TWallet,
  ToConfirmWithdrawProps,
  WillExpireAt,
  compareWithAndBreakPoint,
  getAdditionSizes,
  getModalIds,
  takingIntoCommission,
} from 'ap-core'
import { useUnit } from 'effector-react'
import {
  addIndex,
  any,
  equals,
  fromPairs,
  gt,
  map,
  pipe,
  pluck,
  prop,
  propOr,
} from 'ramda'
import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getGlobalArg } from 'shared'
import { Assets } from './assets'

export const throwSentryError = (e: any) => {
  if (equals('PRODUCTION', getGlobalArg('CURRENT_ENV'))) {
    return captureException(e)
  }
  return
}

const BlockWithLabel = ({
  label,
  data,
  ids,
}: {
  label: string
  data: ReactElement
  ids?: string
}) => {
  return (
    <Flex direction='column'>
      <Text id={`${ids}-Label`} color='fadedGray.7'>
        {label}
      </Text>
      {data}
    </Flex>
  )
}

export const ConfirmWithdraw = ({
  res,
  onSubmit,
  percent,
  currencyID,
  valueToWithdraw,
  currencyName,
  recipient,
  fixedAmount,
  effects,
  verificationType,
  roundToByCurrency,
  fiatList,
}: ToConfirmWithdrawProps) => {
  const { t } = useTranslation()
  const { width } = useViewportSize()

  const loadings = useUnit(pluck('pending', effects))

  const [code, setCode] = useState('')

  const onComplete = (code: string) => {
    onSubmit(code)
    setCode('')
    closeAllModals()
  }

  return (
    <Flex direction='column' gap='sm' mt='xs'>
      <BlockWithLabel
        ids='ConfirmWithdrawBlockWithLabel1'
        label={t('Wallet.FormFields.Amount.label')}
        data={
          <Flex gap={getAdditionSizes('s4')} align='baseline'>
            <BalanceText
              ids='ConfirmWithdrawBlockWithLabelBalance'
              roundToByCurrency={roundToByCurrency}
              fiatList={fiatList}
              currencyID={currencyID}
              amount={valueToWithdraw}
              weight={600}
              fz={getAdditionSizes('s32')}
            />
            <Text id='ConfirmWithdrawBlockWithLabelCurrency' weight={600}>
              {currencyName}
            </Text>
          </Flex>
        }
      />

      <BlockWithLabel
        ids='ConfirmWithdrawBlockWithLabel2'
        label={t('Wallet.Modals.ConfirmWithdraw.Fields.recipient')}
        data={
          <Text
            id='ConfirmWithdrawBlockWithLabelRecipient'
            style={{ overflowWrap: 'break-word' }}
            maw={300}
            weight={600}
          >
            {recipient || '-'}
          </Text>
        }
      />

      <Condition
        when={
          (!isNilOrEmpty(percent) && gt(+percent!, 0)) ||
          (!isNilOrEmpty(fixedAmount) && gt(+fixedAmount!, 0))
        }
      >
        <BlockWithLabel
          ids='ConfirmWithdrawBlockWithLabel3'
          label={t('Wallet.Modals.ConfirmWithdraw.Fields.debitByCommission')}
          data={
            <Flex gap={getAdditionSizes('s4')} align='baseline'>
              <BalanceText
                ids='ConfirmWithdrawBlockWithLabelBalance2'
                roundToByCurrency={roundToByCurrency}
                fiatList={fiatList}
                currencyID={currencyID}
                amount={takingIntoCommission({
                  amount: valueToWithdraw,
                  percent: percent!,
                  fixedAmount,
                })}
                weight={600}
                fz={getAdditionSizes('s32')}
              />
              <Text id='ConfirmWithdrawBlockWithLabelCurrency2' weight={600}>
                {currencyName}
              </Text>
            </Flex>
          }
        />
      </Condition>

      <Flex direction='column' id='ConfirmWithdrawPin'>
        <Pin
          align='flex-start'
          label={t(
            `Wallet.Modals.ConfirmWithdraw.Fields.${
              equals('telegram', verificationType) ? 'TgCode.label' : 'Otp.label'
            }`
          )}
          len={6}
          size={compareWithAndBreakPoint(gt, width, 'md') ? 'xl' : 'md'}
          value={code}
          onChange={setCode}
          onComplete={onComplete}
          disabled={any(Boolean, loadings)}
          autoFocus
        />
        <WillExpireAt
          ids='ConfirmWithdrawWillExpire'
          t={t}
          gap={0}
          expire={propOr(0, 'expirationSecs', res)}
          subTextPath='Shared.Modals.TgVerification.willExpireAt'
        />
      </Flex>
    </Flex>
  )
}

export const openConfirmWithdrawModal = (props: ToConfirmWithdrawProps) => {
  openModal({
    title: props.t('Wallet.Modals.ConfirmWithdraw.title'),
    modalId: getModalIds('confirmWithdraw'),
    padding: 'lg',
    children: <ConfirmWithdraw {...props} />,
  })
}

export const baseIndexingAssets = pipe<
  Assets[],
  TWallet[],
  [string, number][],
  Record<string, number>,
  string
>(
  prop('ungroupedWallets'),
  addIndex<TWallet>(map)(({ id }, i) => [id, i]),
  fromPairs,
  JSON.stringify
)
