import {
  Account,
  AssetInterface,
  CheckBalanceReq,
  MobileWalletTabs,
  P2PInvoice,
  TWallet,
  Transaction,
} from 'ap-core'
import { createEvent } from 'effector'
import { Method } from 'srv-declarations'
import { AssetWs } from '../types'

export const orderDetailsChanged = createEvent<Transaction>()
export const activeWalletChanged = createEvent<TWallet | null>()
export const activeAccountChanged = createEvent<Account | null>()
export const resetSharedAssetsChanged = createEvent()
export const addDemoWallet = createEvent()
export const removeDemoWallet = createEvent()
export const assetSSEChanged = createEvent<AssetWs>()

export const mobileActiveTabChanged = createEvent<MobileWalletTabs>()

export const triggerGetAssets = createEvent()
export const triggerGetAccountsLocations = createEvent()

export const expandedWalletIdChanged = createEvent<string | null>()

export const triggerUpdateAssetsHistory = createEvent<Transaction>()

export const triggerCryptoInvoice = createEvent<Transaction>()
export const currentWalletIdChanged = createEvent<string>()
export const triggerResetHistory = createEvent()

export const triggerCheckBalance = createEvent<CheckBalanceReq>()
export const triggerPaymentMethodChanged = createEvent<Method | null>()

export const ungroupedAssetsChanged = createEvent<TWallet[]>()

export const assetsInterfacesChanged = createEvent<AssetInterface[]>()
export const triggerChangeP2PPaymentStatus = createEvent<P2PInvoice>()

export const triggerGetColdConfig = createEvent()
